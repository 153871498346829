<template>
    <Modal
      :show="showModal"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="onCloseModal"
      size="lg">
      <template v-slot:header>
        <div class="modal-title pb-4">
          <p class="mdl-title">Validasi Pengelolaan Kembali </p>
        </div>
      </template>
      <div class="px-4 border-top">
        <label>Status</label>
        <base-input name="Status" rules="required" >
          <el-select v-model="action">
            <el-option
              v-for="option in optionValue"
              :key="option.val"
              :label="option.name"
              :value="option.val"
            ></el-option>
          </el-select>
        </base-input>

        <label>Keterangan</label>
        <html-editor v-model="verifyNote"></html-editor>

        <div class="form-group row px-3 float-right pb-4">
            <button class="btn btn-primary" @click="onSubmit">KIRIM</button>
        </div>

        <ValidationManualReport :data="data[0]" />

        
        <div>
          <div class="form-group row px-3 float-right pb-4 mt-4">
            <button class="btn btn-warning" @click="onCloseModal">Tutup</button>
          </div>
        </div>
      </div>
    </Modal>
  </template>
  
  <script>
  import flatPicker from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  import axios from "axios";
  import API from "../../../api/base_url.js"
//   import defaults from "@/util/defaults";
  import Modal from '@/components/Modal.vue';
  import HtmlEditor from "@/components/Inputs/HtmlEditor";
  import { Select, Option } from "element-ui";
  import { BaseInput } from "@/components";
import ValidationManualReport from "../../Tables/ValidationManualPengelolaanKembali.vue";
//   import ValidationHistory from "./ValidationHistory.vue";
  
  const flatpickerOption = {
    dateFormat: "U",
    allowInput: true,
    altInput: true,
    altFormat: "d/m/Y",
  };
  
  export default {
    components: {
      ValidationManualReport,
      Modal,
      HtmlEditor,
      flatPicker,
      BaseInput,
      [Select.name]: Select,
      [Option.name]: Option,
    //   ValidationHistory,
    },
    props: {
      showModal: {
        type: Boolean,
        default: false,
      },
      data: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        action: "",
        histories: [],
        verifyNote: "",
        optionValue : [
          {
            name:'Disetujui',
            val : 1
          },
          {
            name:'Ditolak',
            val : 2
          }
        ],
        form: {
          keterangan: null,
          tanggal_uji: null,
          status: null,
          user_id: null,
          uid: null,
          petugas: null,
        },
        flatpickerOption,
      };
    },
    methods: {
      onSubmit() {

        let getOldStatus = ""
        if (this.data[0].length == 0) {
          getOldStatus = 0
        } else {
          getOldStatus = this.data[0][this.data[0].length - 1].old_status
        }

        if (!this.action) {
          Swal.fire({
            icon: 'error',
            text: `Data anda belum lengkap`,
          });
        } else {
          let payload = {
            new_status: this.action,
            note: this.verifyNote,
            old_status: getOldStatus,
            waste_use_id: this.data[1].id
          }


          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
          API.post(`companies/waste-uses/histories` , payload, {headers})
          .then(({data}) => {
            console.log(data,'----------->');
            if (this.action == '1') {
              Swal.fire({
                icon: 'success',
                text: `Validasi telah diterima 123`,
              });
            } else if (this.action == '2') {
              Swal.fire({
                icon: 'success',
                text: `Validasi telah ditolak 123`,
              });
            }
            this.resetPayload()
            this.$emit("close");
          })
          .catch((err) => {
            console.log(err,'----> apakah ni erornya');
            Swal.fire({
                icon: 'error',
                text: `Gagal`,
              });
          })
        }
      },
      resetPayload() {
        this.action = ""
        this.verifyNote = ""
      },
      // async onSubmit() {
      //   const auth = JSON.parse(localStorage.getItem("auth-data"));
      //   this.form.user_id = auth.name;
      //   const tempValidate = await this.$refs.formValidator.validate();
      //   if (!tempValidate) {
      //     Swal.fire({
      //         icon: 'error',
      //         text: `Data belum lengkap`,
      //       });
      //   } else {
      //     const data = await axios.post(`${defaults.baseURL}/api/v1/ujikonek/validasi/${this.data.id}`, this.form);
      //     if (data.status === 200) {
      //       Swal.fire({
      //         icon: 'success',
      //         title: 'Tersimpan',
      //         text: `Data telah tersimpan`,
      //       }).then(() => {
      //         this.$emit("close");
      //       });
      //     } else {
      //       Swal.fire({
      //         icon: 'error',
      //         title: 'Gagal',
      //         text: `Silakan Coba Kembali`,
      //      });
      //     }
      //   }
      // },
      onCloseModal() {
        this.$emit("close");
      },
    },
  };
  </script>
  <style scoped>
  .mdl-title {
    font-size: 18px;
    font-weight: 600;
    color: #6C757D;
    width: 100%;
  }
  </style>