<template>
    <div>
      <base-header class="pb-6">
        <b-row class="py-4">
          <b-col lg="6" cols="7">
            <h6 class="h2 text-white d-inline-block mb-0">Rincian Pengolahan Air limbah</h6>
          </b-col>
          <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
            <base-button @click="back()">Kembali</base-button>
          </b-col>
        </b-row>
      </base-header>
  
      <div class="container-fluid mt--6">
        <div class="row justify-content-center mb--4">
          <div  class="col">
            <b-card>
              <div v-if="isLoading">
                  <h1>Loading</h1>
              </div>

              <div  v-else>
                <div class="card-body">

                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Industri</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.comp_name }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Dibuat oleh</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.created_by }} - {{ formatDate(listData.created_at) }}</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit air recovery</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_recovery.value }} {{ listData.debit_recovery.unit }} (<i>{{ listData.debit_recovery.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit air recycle</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_recycle.value}} {{ listData.debit_recycle.unit }} (<i>{{ listData.debit_recycle.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit air reuse</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_reuse.value }} {{ listData.debit_reuse.unit }} (<i>{{ listData.debit_reuse.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit Air Limbah Diolah IPAL</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_olah_ipal.value }} {{ listData.debit_olah_ipal.unit }} (<i>{{ listData.debit_olah_ipal.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit Air Limbah Total</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_limbah_total.value }} {{ listData.debit_limbah_total.unit }} (<i>{{ listData.debit_limbah_total.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Debit Penggunaan air</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.debit_penggunaan.value }} {{ listData.debit_penggunaan.unit }} (<i>{{ listData.debit_penggunaan.desc }}</i> )</span>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Permohonan Koneksi</strong>
                        </div>
                        <div class="col-lg-9">
                          <a :href="`https://${listData.connection_request_path}`" target="blank">Lihat file</a>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Unggah Neraca Air Dan Unggah Air Limbah</strong>
                        </div>
                        <div class="col-lg-9">
                          <a :href="`https://${listData.water_and_was_water_balance_path}`" target="blank">Lihat file</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </b-card>
          </div>
          <div class="row justify-content-end position-fixed right-0" style="top:50%">
            <base-button @click="validasiHandler" class="mx-3 mb-4" type="danger">
              <!-- {{ modalData.isValidated ? "Riwayat Validasi" : "Validasi" }} -->
              Validasi
            </base-button>
          </div>
        </div>
      </div>
      <ModalValidasi v-if="showModal" :data="[listRiwayat, listData]" :show-modal="showModal" @close="onCloseModal" />
    </div>
  </template>
  <script>
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import BaseHeader from "@/components/BaseHeader";
  import API from "../../../../api/base_url.js"
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  import ModalValidasi from "../ModalValidasiPengelolaanKembali.vue";
  import moment from 'moment'

  export default {
    components: {
      BaseHeader,
      RouteBreadCrumb,
      ModalValidasi
    },
    mounted() {
      this.getData()
    },
    data() {
      return {
        showModal:false,
        listData: [],
        listRiwayat: [],
        showModal:false,
        isLoading: true
      };
    },
    methods: {
      validasiHandler() {
        this.showModal = true;
      },
      onCloseModal() {
        this.showModal = false;
        this.getData();
      },
      getData() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`waste-recycles/${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          this.listData = content.data
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err);
        })
      },
      formatDate(date) {
        return moment(date).format("DD/MM/YYYY");
      },
      back() {
        this.$router.push("/verifikasi/pengolahan-air-kembali");
      },
    },
  };
  </script>
  <style></style>
  